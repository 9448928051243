import RouterSection from "@/components/Layouts/RouterSection/RouterSection";
import { chkAccess } from "@/api/Admin/Access";
export default {
  path: "/admin",
  name: "InfoSK_Admin",
  component: RouterSection,
  redirect: { name: "AdminDashboard" },
  children: [
    {
      path: "/admin",
      name: "AdminDashboard",
      component: () =>
        import(
          /* webpackChunkName: "AdminDashboard" */ "@/views/Admin/AdminDashboard.vue"
        ),
    },
    {
      path: "/admin/user",
      name: "AdminUserAccount",
      component: () =>
        import(
          /* webpackChunkName: "AdminUserAccount" */ "@/views/Admin/AdminUserAccount.vue"
        ),
    },
    {
      path: "/admin/permission",
      name: "AdminUserPermission",
      component: () =>
        import(
          /* webpackChunkName: "AdminUserPermission" */ "@/views/Admin/AdminUserPermission.vue"
        ),
    },
    {
      path: "/admin/company",
      name: "AdminCompany",
      component: () =>
        import(
          /* webpackChunkName: "AdminCompany" */ "@/views/Admin/AdminCompany.vue"
        ),
    },
    {
      path: "/admin/company/add/all",
      name: "AdminAddAllCompany",
      component: () =>
        import(
          /* webpackChunkName: "AdminAddAllCompany" */ "@/views/Admin/AdminAddAllCompany.vue"
        ),
    },
    {
      path: "/admin/product",
      name: "AdminProduct",
      component: () =>
        import(
          /* webpackChunkName: "AdminProduct" */ "@/views/Admin/AdminProduct.vue"
        ),
    },
    {
      path: "/admin/product/add/all",
      name: "AdminAddAllProduct",
      component: () =>
        import(
          /* webpackChunkName: "AdminAddAllProduct" */ "@/views/Admin/AdminAddAllProduct.vue"
        ),
    },
    {
      path: "/admin/product/version/add/all",
      name: "AdminAddAllProductVersion",
      component: () =>
        import(
          /* webpackChunkName: "AdminAddAllProductVersion" */ "@/views/Admin/AdminAddAllProductVersion.vue"
        ),
    },
    {
      path: "/admin/contract",
      name: "AdminContract",
      component: () =>
        import(
          /* webpackChunkName: "AdminContract" */ "@/views/Admin/AdminContract.vue"
        ),
    },
    {
      path: "/admin/contract/add/all",
      name: "AdminAddAllContract",
      component: () =>
        import(
          /* webpackChunkName: "AdminAddAllContract" */ "@/views/Admin/AdminAddAllContract.vue"
        ),
    },
    {
      path: "/admin/charts",
      name: "AdminCharts",
      component: () =>
        import(
          /* webpackChunkName: "AdminCharts" */ "@/views/Admin/AdminCharts.vue"
        ),
    },
    {
      path: "/admin/report",
      name: "AdminReport",
      component: () =>
        import(
          /* webpackChunkName: "AdminReport" */ "@/views/Admin/AdminReport.vue"
        ),
    },
    {
      path: "/admin/system",
      name: "AdminSystem",
      component: () =>
        import(
          /* webpackChunkName: "AdminSystem" */ "@/views/Admin/AdminSystem.vue"
        ),
    },
    {
      path: "/admin/bugreport",
      name: "AdminBugreport",
      component: () =>
        import(
          /* webpackChunkName: "AdminBugreport" */ "@/views/Admin/AdminBugreport.vue"
        ),
    },
    {
      path: "/admin/case",
      name: "AdminCase",
      component: () =>
        import(
          /* webpackChunkName: "AdminCase" */ "@/views/Admin/AdminCase.vue"
        ),
    },
    {
      path: "/admin/data",
      name: "AdminData",
      component: () =>
        import(
          /* webpackChunkName: "AdminData" */ "@/views/Admin/AdminData.vue"
        ),
    },
  ],
  beforeEnter: async (to, from, next) => {
    const access = await chkAccess();
    if (!access.success) {
      alert(access.message.text);
      next("/");
    } else {
      next();
    }
  },
};
