import RouterSection from "@/components/Layouts/RouterSection/RouterSection";
import { checkLogin } from "@/api/Auth";
import { chkAccess } from "@/api/Admin/Access";
import store from "@/store";
import routerutil from "../routerutil";
export default {
  path: "/basic",
  name: "InfoSK_Basic",
  component: RouterSection,
  redirect: { name: "MainDashBoard" },
  children: [
    {
      path: "/basic",
      name: "MainDashBoard",
      component: () =>
        import(
          /* webpackChunkName: "MainDashboard" */ "@/views/Basic/MainDashboard.vue"
        ),
    },
    {
      path: "/case/new",
      name: "NewCase",
      component: () =>
        import(/* webpackChunkName: "NewCase" */ "@/views/Basic/NewCase.vue"),
    },

    {
      path: "/case/:caseUUID",
      name: "ViewCase",
      props: true,
      component: () =>
        import(/* webpackChunkName: "VueCase" */ "@/views/Basic/ViewCase.vue"),
    },
    {
      path: "/case/new/tech",
      name: "NewCaseTech",
      component: () =>
        import(
          /* webpackChunkName: "NewCaseTech" */ "@/views/Basic/NewCaseTech.vue"
        ),
    },
    {
      path: "/case/new/fulfill",
      name: "NewCaseFulfill",
      component: () =>
        import(
          /* webpackChunkName: "NewCaseFulfill" */ "@/views/Basic/NewCaseFulfill.vue"
        ),
    },
    {
      path: "/case/new/admst",
      name: "NewCaseAdmst",
      component: () =>
        import(
          /* webpackChunkName: "NewCaseAdmst" */ "@/views/Basic/NewCaseAdmst.vue"
        ),
    },
  ],
  beforeEnter: async (to, from, next) => {
    const publicSetting = store.getters["settings/publicSetting"];
    if (publicSetting.IS_CHECK_TIME) {
      const access = await chkAccess();
      if (!access.success) {
        return next("/Maintenance");
      } else {
        next();
      }
    }
    routerutil.checkServiceTime(next);
    const login_info = await checkLogin();
    if (login_info.success) {
      if (!login_info.login_status) {
        alert(login_info.message);
        store.commit("userInfo/setLoginRedirect", to);
        return next("/Login");
      } else {
        return next();
      }
    } else {
      store.commit("userInfo/setLoginRedirect", to);
      return next("/Login");
    }
  },
};
