import * as axios from "axios";

const getFileName = (contentDescription) => {
  const regex =
    /(?<=filename(?:=|\*=(?:[\w-]+'')))["']?(?<filename>[^"';\n]+)["']?/g;
  // const regex = /filename=["'](?<filename>.*)["']/g;
  const matches = contentDescription.matchAll(regex);
  const groups = Array.from(matches).map((match) => match?.groups?.filename);
  console.log(groups);
  if (groups.length === 2) {
    return decodeURI(groups[1]);
  }
  if (groups.length === 1) {
    return groups[0];
  }
};

export const requests = async ({ url, method, data, options, extend }) => {
  // 추가 옵션값 설정
  const extOpt = { ...extend };
  extOpt.silence = extOpt.silence || false;
  const option = {
    url,
    method,
    data,

    ...options,
  };

  const token = localStorage.getItem("token");
  if (token != null) {
    option.headers = { Authorization: `Basic ${token}` };
  }
  let res = await axios(option);
  let res_data = res.data;

  if (res.headers["content-type"] != null) {
    const contentType = res.headers["content-type"].split(";")[0].trim();

    if (contentType == "application/json") {
      // 데이터가 JSON 이면 JSON 검증을 통해 로그인 처리를 하고 메시징 처리후 데이터를 반환한다.
      if (res_data.success) {
        if (res_data.message != null) {
          const message = res_data.message;
          if (message.text != null && !extOpt.silence) alert(message.text);
        }
        if (res_data.login_status != null && res_data.login_status == false) {
          if (res_data.data.relogin == true) {
            const refToken = localStorage.getItem("refToken");
            const ref = await axios({
              url: "/api/public/auth/login",
              method: "put",
              data: { refToken },
              headers: { Authorization: `Basic ${token}` },
            });
            const res = ref.data;
            if (res.success && res.login_status) {
              const newToken = res.data.newToken;
              const newRefToken = res.data.newRefToken;
              localStorage.setItem("token", newToken);
              localStorage.setItem("refToken", newRefToken);
              console.log("Refrash Success");
              return await requests({ url, method, data, options });
            } else {
              localStorage.clear();
            }
          }
        }
      } else {
        if (res_data.relogin) {
          const refToken = localStorage.getItem("refToken");
          const ref = await axios({
            url: "/api/public/auth/login",
            method: "put",
            data: { refToken },
            headers: { Authorization: `Basic ${token}` },
          });
          const res = ref.data;
          if (res.success && res.login_status) {
            const newToken = res.data.newToken;
            const newRefToken = res.data.newRefToken;
            localStorage.setItem("token", newToken);
            localStorage.setItem("refToken", newRefToken);
            return await requests({ url, method, data, options });
          } else {
            localStorage.clear();
          }
        } else if (res_data.message != null && !extOpt.silence) {
          const message = res_data.message;
          if (message.text != null) alert(message.text);
        }
      }
    } else {
      // 데이터가 파일이면 다운로드 로직을 통해 파일을 다운로드 처리 한다.
      if (res.headers["content-disposition"] != null) {
        const contentDescription = res.headers["content-disposition"];
        const contentDescriptionList = contentDescription.split(";");
        const is_attachment = contentDescriptionList[0].trim();
        if (is_attachment == "attachment") {
          const fileName = getFileName(contentDescription);
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: res.headers["content-type"],
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          link.style.cssText = "display:none";
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    }
  }
  return res;
};

export const json2Query = (jsondata) => {
  return Object.entries(jsondata)
    .map(([key, value]) => value && key + "=" + value)
    .filter((v) => v)
    .join("&");
};
