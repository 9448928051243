import RouterSection from "@/components/Layouts/RouterSection/RouterSectionPost";
import { checkLogin } from "@/api/Auth";
import store from "@/store";
// import { chkAccess } from "@/api/Admin/Access";
// import store from "@/store";
export default {
  path: "/posts",
  name: "InfoSK_Posts",
  component: RouterSection,
  redirect: { name: "PostList" },
  children: [
    {
      path: "/posts/list",
      name: "PostList",
      component: () =>
        import(/* webpackChunkName: "PostList" */ "@/views/Posts/List.vue"),
    },
    {
      path: "/posts/view/:post_id",
      name: "PostView",
      props: true,
      component: () =>
        import(/* webpackChunkName: "PostView" */ "@/views/Posts/View.vue"),
    },
    {
      path: "/posts/editor/:post_id?",
      name: "PostEditor",
      props: true,
      component: () =>
        import(/* webpackChunkName: "PostEditor" */ "@/views/Posts/Editor.vue"),
    },
  ],
  beforeEnter: async (to, from, next) => {
    const login_info = await checkLogin();
    if (login_info.success) {
      if (!login_info.login_status) {
        alert(login_info.message);
        store.commit("userInfo/setLoginRedirect", to);
        return next("/Login");
      } else {
        return next();
      }
    } else {
      store.commit("userInfo/setLoginRedirect", to);
      return next("/Login");
    }
  },
};
