import Vue from "vue";
import VueRouter from "vue-router";
import RouterSection from "@/components/Layouts/RouterSection/RouterSection";

import store from "@/store";
import { getSystemSettings } from "@/api/System";

import router_basics from "./modules/basics";
import router_users from "./modules/users";
import router_admin from "./modules/admin";
import router_posts from "./modules/posts";

let home = "InfoSK_Basic";
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: home },
    component: RouterSection,
    children: [
      {
        path: "/Login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "@/views/Login/Login.vue"),
      },
    ],
  },
  router_basics,
  router_users,
  router_admin,
  router_posts,
  {
    path: "/Maintenance",
    component: () =>
      import(
        /* webpackChunkName: "Maintenance" */ "@/views/Pages/Maintenance.vue"
      ),
  },
  {
    path: "/ServiceDown",
    component: () =>
      import(
        /* webpackChunkName: "ServiceDown" */ "@/views/Pages/ServiceDown.vue"
      ),
  },
  {
    path: "/Help",
    component: () =>
      import(/* webpackChunkName: "Help" */ "@/views/Pages/Help.vue"),
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/Pages/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const setting_parser = (setting_array) => {
  const settings = setting_array.map((e) => {
    if (e.setting_value_type === "TEXT")
      return { [e.setting_name]: e.setting_text_value };
    if (e.setting_value_type === "INT")
      return { [e.setting_name]: e.setting_int_value };
    if (e.setting_value_type === "FLOAT")
      return { [e.setting_name]: e.setting_float_value };
    return { [e.setting_name]: e.setting_text_value };
  });
  return Object.assign({}, ...settings);
};

router.beforeEach(async (to, from, next) => {
  const setting_rsp = await getSystemSettings();
  if (setting_rsp.success) {
    const settings = setting_parser(setting_rsp.data);
    store.commit("settings/setPublicSetting", settings);
  }
  next();
});

export default router;
