const userStore = {
  namespaced: true,
  state: {
    selectedCompany: null,
    selectedContract: null,
  },
  getters: {
    selectedCompany(state) {
      return state.selectedCompany;
    },
    selectedContract(state) {
      return state.selectedContract;
    },
  },
  mutations: {
    updateSelection(state, { selectedCompany, selectedContract }) {
      state.selectedCompany = selectedCompany;
      state.selectedContract = selectedContract;
    },
  },
};

export default userStore;
