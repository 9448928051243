import Vue from "vue";
import Vuex from "vuex";

import userInfo from "@/store//modules/userInfo";
import mainDash from "@/store//modules/mainDash";
import settings from "@/store//modules/settings";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    NavitationDrawer: false,
    isLogin: false,
  },
  mutations: {
    changeNavitationDrawer(state) {
      state.NavitationDrawer = !state.NavitationDrawer;
    },
  },
  actions: {},
  modules: { userInfo, mainDash, settings },
});

export default store;
