import { requests, json2Query } from "@/utils/requests";

export const getPosts = async (param) => {
  const query = json2Query(param);
  console.log(query);
  const data = await requests({
    url: `/api/service/post?${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const getPostsKB = async (param) => {
  const query = json2Query(param);
  console.log(query);
  const data = await requests({
    url: `/api/service/post/KB?${query}`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const delPosts = async (param) => {
  const { post_id } = param;
  const data = await requests({
    url: `/api/admin/post/${post_id}`,
    method: "DELETE",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const putPosts = async (param) => {
  const data = await requests({
    url: `/api/admin/post`,
    method: "PUT",
    data: param,
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const newPosts = async (param) => {
  const data = await requests({
    url: `/api/admin/post`,
    method: "POST",
    data: param,
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getPostCounts = async () => {
  const data = await requests({
    url: `/api/service/post/counts`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const getUnreadPosts = async () => {
  const data = await requests({
    url: `/api/service/post/unreads`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};
export const readPosts = async (param) => {
  const data = await requests({
    url: `/api/service/post/read/${param.post_id}`,
    method: "POST",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getPosts,
  getPostCounts,
  getUnreadPosts,
};
