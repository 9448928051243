import { requests } from "@/utils/requests";

export const checkLogin = async () => {
  const data = await requests({
    url: "/api/public/auth/login",
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const doLogin = async (user_id, user_pw) => {
  const data = await requests({
    url: "/api/public/auth/login",
    method: "POST",
    data: {
      user_id,
      user_pw,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  checkLogin,
};
