const settingStore = {
  namespaced: true,
  state: {
    publicSetting: null,
  },
  getters: {
    publicSetting(state) {
      return state.publicSetting;
    },
  },
  mutations: {
    setPublicSetting(state, settings) {
      state.publicSetting = settings;
    },
  },
};

export default settingStore;
