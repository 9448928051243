import RouterSection from "@/components/Layouts/RouterSection/RouterSection";
import { checkLogin } from "@/api/Auth";
import { chkAccess } from "@/api/Admin/Access";
import store from "@/store";
export default {
  path: "/user",
  name: "InfoSK_User",
  component: RouterSection,
  redirect: { name: "MyPage" },
  children: [
    {
      path: "/user/mypage",
      name: "MyPage",
      component: () =>
        import(/* webpackChunkName: "MyPage" */ "@/views/User/MyPage.vue"),
    },
  ],
  beforeEnter: async (to, from, next) => {
    const publicSetting = store.getters["settings/publicSetting"];
    if (publicSetting.IS_CHECK_TIME) {
      const access = await chkAccess();
      if (!access.success) {
        return next("/Maintenance");
      } else {
        next();
      }
    }
    const login_info = await checkLogin();
    if (login_info.success) {
      if (!login_info.login_status) {
        alert(login_info.message);
        store.commit("userInfo/setLoginRedirect", to);
        return next("/Login");
      } else {
        return next();
      }
    } else {
      store.commit("userInfo/setLoginRedirect", to);
      return next("/Login");
    }
  },
};
