<template>
  <v-navigation-drawer v-model="$store.state.NavitationDrawer" app>
    <v-list nav dense>
      <v-list-item-group
        v-model="selectedMenuIDX"
        :no-action="true"
        active-class="light-blue--text text--darken-4"
      >
        <v-list-item
          @click="goPath(menu.path)"
          v-for="menu in menus"
          :key="menu.id"
        >
          <v-list-item-icon>
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  mounted() {
    this.setSelectes();
    this.isAdmin();
  },
  watch: {
    myInfo() {
      this.isAdmin();
    },
    $route() {
      this.setSelectes();
    },
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
  },
  methods: {
    goPath(path) {
      this.$router.push(path);
      this.$store.state.NavitationDrawer = false;
    },
    setSelectes() {
      const currentRoute = this.$router.history.current;
      const idx = this.menus.findIndex((e) => e.path == currentRoute.path);
      if (idx == -1) {
        this.selectedMenuIDX = null;
      }
      this.selectedMenuIDX = idx;
    },
    isAdmin() {
      if (
        this.myInfo != null &&
        (this.myInfo.user_level == 6 || this.myInfo.user_level == 7)
      ) {
        this.menus = [
          { id: 0, title: "Home", icon: "mdi-home", path: "/basic" },
          { id: 1, title: "Posts", icon: "mdi-post", path: "/posts/list" },
          { id: 2, title: "Admin", icon: "mdi-cog", path: "/admin" },
        ];
      } else {
        this.menus = [
          { id: 0, title: "Home", icon: "mdi-home", path: "/basic" },
          { id: 1, title: "Posts", icon: "mdi-post", path: "/posts/list" },
        ];
      }
    },
  },
  data() {
    return {
      menus: [{ id: 0, title: "Home", icon: "mdi-home", path: "/basic" }],
      selectedMenuIDX: 0,
    };
  },
};
</script>
