<template>
  <v-fade-transition leave-absolute>
    <router-view :key="key" />
  </v-fade-transition>
</template>

<script>
import { chkAccess } from "@/api/Admin/Access";
import store from "@/store";
import moment from "moment";
export default {
  name: "RouterSection",
  beforeUpdate() {
    this.checkMainTenance();
  },
  methods: {
    async checkMainTenance() {
      const publicSetting = store.getters["settings/publicSetting"];
      if (publicSetting.IS_CHECK_TIME) {
        const access = await chkAccess();
        if (!access.success) {
          return this.$router.push("/Maintenance");
        }
      }
    },
    async checkServiceTime() {
      const publicSetting = store.getters["settings/publicSetting"];
      if (!publicSetting.SERVICE_DOWN_ON) return;
      const service_dow = publicSetting.SERVICE_DOW.split(",");
      console.log();
      const service_end_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.SERVICE_END_TIME
      );
      const service_start_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.SERVICE_START_TIME
      );
      const today = moment();

      const is_inTime = service_end_time > today && service_start_time < today;
      const is_inDow = service_dow.find((e) => e == today.days()) != null;

      if ((is_inTime && is_inDow) != true) {
        const access = await chkAccess();
        if (!access.success) {
          return this.$router.push("/ServiceDown");
        }
      }
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isLocal() {
      return process.env.NODE_ENV === "development";
    },
  },
};
</script>
