import { requests } from "@/utils/requests";

export const getSystemSettings = async () => {
  const data = await requests({
    url: `/api/public/setting`,
    method: "GET",
  });
  if (data.status == 200) return data.data;
  else return data;
};

export const updateSystemSettings = async (setting_name, setting_data) => {
  const data = await requests({
    url: `/api/admin/setting`,
    method: "PUT",
    data: { setting_name, setting_data },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  getSystemSettings,
};
