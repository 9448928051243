const userStore = {
  namespaced: true,
  state: {
    userData: localStorage.getItem("userData"),
    token: localStorage.getItem("userData"),
    loginRedirect: null,
  },
  getters: {
    isLogin(state) {
      return state.token != null;
    },
    userData(state) {
      return state.userData;
    },
    loginRedirect(state) {
      return state.loginRedirect;
    },
  },
  mutations: {
    updateInfo(state) {
      state.userData = localStorage.getItem("userData");
      state.token = localStorage.getItem("token");
    },
    setLoginRedirect(state, data) {
      state.loginRedirect = data;
    },
  },
};

export default userStore;
