<template>
  <v-app>
    <Header />
    <Navigation />
    <v-main>
      <v-fade-transition leave-absolute>
        <router-view />
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Layouts/Header/Header";
import Navigation from "@/components/Layouts/Navigation/Navigation";
export default {
  name: "App",
  components: { Header, Navigation },
  data: () => ({
    //
  }),
};
</script>

<style>
main {
  background-color: #fafafa;
}
</style>
