import { requests } from "@/utils/requests";

export const chkAccess = async () => {
  const data = await requests({
    url: `/api/admin/access`,
    method: "GET",
    extend: {
      silence: true,
    },
  });
  if (data.status == 200) return data.data;
  else return data;
};

export default {
  chkAccess,
};
