<template>
  <div>
    <v-dialog v-model="unReadNotice" width="800" persistent>
      <v-card class="pa-0">
        <v-card-text class="pa-0">
          <v-alert :color="PostTypeColor" type="error" class="font-weight-bold">
            읽지 않은 {{ PostType }} 가 있습니다.
          </v-alert>
        </v-card-text>
        <v-card-text>
          <span class="title black--text font-weight-bold">
            운영자가 게시한 알림을 읽은 후 시스템을 이용할 수 있습니다.
          </span>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="auto" class="ml-auto pa-0">
                <v-btn
                  color="success"
                  large
                  @click="$router.push('/posts')"
                  tile
                  class="font-weight-bold title"
                >
                  바로가기
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-fade-transition leave-absolute>
      <router-view :key="key" />
    </v-fade-transition>
  </div>
</template>

<script>
import { chkAccess } from "@/api/Admin/Access";
import { getUnreadPosts } from "@/api/Post";
import store from "@/store";
import moment from "moment";
export default {
  name: "RouterSection",
  beforeUpdate() {
    this.checkMainTenance();
    this.checkServiceTime();
  },
  updated() {},
  mounted() {
    this.checkUnreadPost();
  },
  methods: {
    async checkMainTenance() {
      const publicSetting = store.getters["settings/publicSetting"];
      if (publicSetting.IS_CHECK_TIME) {
        const access = await chkAccess();
        if (!access.success) {
          return this.$router.push("/Maintenance");
        }
      }
    },
    async checkServiceTime() {
      const publicSetting = store.getters["settings/publicSetting"];
      if (!publicSetting.SERVICE_DOWN_ON) return;
      const service_dow = publicSetting.SERVICE_DOW.split(",");
      console.log();
      const service_end_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.SERVICE_END_TIME
      );
      const service_start_time = moment(
        moment().format("yyyy-MM-DD") + " " + publicSetting.SERVICE_START_TIME
      );
      const today = moment();

      const is_inTime = service_end_time > today && service_start_time < today;
      const is_inDow = service_dow.find((e) => e == today.days()) != null;

      if ((is_inTime && is_inDow) != true) {
        const access = await chkAccess();
        if (!access.success) {
          return this.$router.push("/ServiceDown");
        }
      }
    },
    async checkUnreadPost() {
      // if (this.isAdmin) return;
      const data = await getUnreadPosts();
      if (data.success) {
        this.unreadData = data.data;
        for (const ud of this.unreadData) {
          console.log(ud);
          if (ud.post_type == "Notice") {
            this.unReadNotice = true;
          } else if (ud.post_type == "Announcement") {
            this.unReadNotice = true;
          }
        }
      }
    },
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isLocal() {
      return process.env.NODE_ENV === "development";
    },
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    isAdmin() {
      if (this.myInfo == null) return false;
      return this.myInfo.user_level > 3;
    },
    PostType() {
      if (this.unreadData == null) return "error";
      for (const ud of this.unreadData) {
        console.log(ud);
        if (ud.post_type == "Notice") {
          return ud.post_type;
        } else if (ud.post_type == "Announcement") {
          return ud.post_type;
        }
      }
      return "error";
    },
    PostTypeColor() {
      if (this.PostType === "Notice") return "error";
      if (this.PostType === "Announcement") return "indigo";
      if (this.PostType === "KnowledgeBase") return "warning";
      if (this.PostType === "Article") return "info";
      return "";
    },
  },
  data() {
    return { unReadNotice: false, unreadData: null };
  },
};
</script>
