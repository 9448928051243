<template>
  <v-app-bar app color="white" dense>
    <v-app-bar-nav-icon
      @click="navigatorBtnClick"
      v-if="isLogin"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>
      <div class="d-flex align-center" style="cursor: pointer" @click="goMain">
        <v-img
          alt="informatica-logo"
          class="shrink mr-2 d-none d-sm-block"
          contain
          src="~@/assets/logo/INFA_ISK_LOGO.svg"
          width="220"
        />
        <h4 class="title font-weight-bold">
          <span class="d-sm-none">ISK</span> CUSTOMER SUPPORT
        </h4>
      </div>
    </v-toolbar-title>

    <v-menu
      transition="scroll-y-transition"
      offset-y
      offset-overflow
      left
      v-if="isLogin"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-auto mx-1 px-0"
          elevation="0"
          color="white"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item link class="text-center px-6" @click="goMypage">
          <v-list-item-title>MY PAGE</v-list-item-title>
        </v-list-item>
        <v-list-item link class="text-center px-6" @click="goLogout">
          <v-list-item-title>LOGOUT</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn
      :class="{ 'ml-auto': !isLogin }"
      elevation="0"
      color="white"
      @click="$router.push('/help')"
      v-if="isLogin"
    >
      <v-icon>mdi-bug</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  methods: {
    goMain() {
      if (this.isLogin) this.$router.push("/");
      else this.$router.push("/Login");
    },
    goLogout() {
      localStorage.clear();
      this.$store.commit("userInfo/updateInfo");
      this.$router.push("/Login");
    },
    goMypage() {
      this.$router.push("/User/Mypage");
    },
    navigatorBtnClick() {
      if (this.isLogin) {
        this.$store.commit("changeNavitationDrawer");
      } else {
        this.$store.state.NavitationDrawer = false;
      }
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters["userInfo/isLogin"];
    },
  },
  data() {
    return {
      menuDrawer: false,
    };
  },
};
</script>
